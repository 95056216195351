import React from "react";
import { m } from "framer-motion";
import MoreLink from "components/MoreLink";
import NodeRealisationCaseStudyList from "components/Realisation/NodeRealisationCaseStudyList";
import Scribbles5 from "components/SVG/Scribble/Scribbles5";
import { fadeIn, fadeUp } from "lib/variants";
import Headings from "components/Headings";

export default function CaseStudiesSections({ nodes }) {
  return (
    <m.section variants={fadeIn} className="mb-16 2xl:mb-28">
      <div className="relative container px-4 lg:px-12 mx-auto">
        <Headings
          pretitle="On ne doute pas du fait que vous ayez compris ce que l’on fait mais…"
          title="Zoom sur quelques projets"
          scribble={
            <Scribbles5 className="absolute top-1/2 left-0 -translate-y-1/2 w-1/3 md:w-[10.15vw] text-primary" />
          }
        />

        <NodeRealisationCaseStudyList nodes={nodes} />

        <m.div
          variants={fadeUp}
          initial="out"
          whileInView="enter"
          className="lg:text-right mt-12 will-change-transform"
        >
          <MoreLink href="/realisations">Toutes nos études de cas</MoreLink>
        </m.div>
      </div>
    </m.section>
  );
}
