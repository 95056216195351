import React, { createRef, useEffect, useState } from "react";
import { m } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import Button from "components/Button";
import Body from "components/Body";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { fadeIn, fadeUp } from "lib/variants";

export default function NodeRealisationCaseStudyList({ nodes }) {
  const [index, setIndex] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = createRef();
  const active = nodes[index];
  const hasBaseline = active.field_baseline || false;
  const hasArrows = nodes.length > 1;

  useEffect(() => {
    if (ref.current) {
      setHeight((h) =>
        ref.current.offsetHeight > h ? ref.current.offsetHeight : h
      );
    }
  }, [index]);

  return (
    <m.article
      ref={ref}
      variants={fadeUp}
      initial="out"
      whileInView="enter"
      className="flex flex-col lg:flex-row lg:flex-wrap rounded-3xl overflow-hidden bg-dark text-light will-change-transform"
      style={{
        minHeight: `${height}px`,
      }}
    >
      <div className="relative flex-initial w-full lg:w-5/12">
        <m.div
          key={active.id}
          variants={fadeIn}
          initial="out"
          animate="enter"
          className="pb-[80%] md:pb-[33%] lg:pb-[80%]"
        >
          <Image
            src={active.field_image.image_style_uri.large_square}
            alt={active.field_image.resourceIdObjMeta.alt}
            className="object-cover rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none"
            fill
          />
        </m.div>

        <div
          className="absolute inset-0 bg-gradient-to-t from-dark via-transparent"
          aria-hidden="true"
        />

        {hasArrows && (
          <div className="absolute right-0 bottom-0 flex flex-row lg:flex-col">
            <button
              type="button"
              className="group p-3 bg-light text-dark"
              onClick={() =>
                setIndex((current) =>
                  current === 0 ? nodes.length - 1 : current - 1
                )
              }
            >
              <ArrowLeftIcon className="h-8 w-8 group-hover:-translate-x-1 transition-transform duration-300" />
              <span className="sr-only">Précédent</span>
            </button>
            <button
              type="button"
              className="group p-3 bg-primary text-dark"
              onClick={() =>
                setIndex((current) =>
                  current === nodes.length - 1 ? 0 : current + 1
                )
              }
            >
              <ArrowRightIcon className="h-8 w-8 group-hover:translate-x-1 transition-transform duration-300" />
              <span className="sr-only">Suivant</span>
            </button>
          </div>
        )}
      </div>
      <div
        key={active.id}
        className="flex flex-1 flex-col w-full lg:w-7/12 p-8 lg:px-12 lg:py-16"
      >
        <div className="flex-1 ">
          <m.h3
            variants={fadeUp}
            initial="out"
            animate="enter"
            className="text-2xl 2xl:text-4xl font-important tracking-wider font-semibold will-change-transform"
          >
            {active.title}
          </m.h3>

          {hasBaseline && (
            <m.div
              variants={fadeUp}
              initial="out"
              animate="enter"
              className="prose prose-light mt-2 leading-none will-change-transform"
            >
              {active.field_baseline}
            </m.div>
          )}

          {active.body && (
            <m.div
              variants={fadeUp}
              initial="out"
              animate="enter"
              className="prose prose-light mt-10 will-change-transform"
            >
              <Body value={active.body.summary || active.body.value} />
            </m.div>
          )}
        </div>

        <div className="mt-10">
          <Link
            href={active.path.alias}
            prefetch={false}
            passHref
            legacyBehavior
          >
            <Button as="a">Lire la suite</Button>
          </Link>
        </div>
      </div>
    </m.article>
  );
}
